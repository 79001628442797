import React from "react";

import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminDisclosure,
} from "@containers";

const AdminOpenbaarheid = props => (
    <Layout {...props}>
        <DefaultLayout title="Beheer parameters openbaarheid">
            <AdminDisclosure id="beheer-openbaarheid" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AdminOpenbaarheid);
